// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-gatsby-js": () => import("./../../../src/pages/gatsby.js" /* webpackChunkName: "component---src-pages-gatsby-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-norway-js": () => import("./../../../src/pages/norway.js" /* webpackChunkName: "component---src-pages-norway-js" */),
  "component---src-pages-posts-js": () => import("./../../../src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */),
  "component---src-templates-post-single-js": () => import("./../../../src/templates/postSingle.js" /* webpackChunkName: "component---src-templates-post-single-js" */)
}

